import React from 'react'

import BannerBg from "../../Images/background/home-bg.jpg"
import newBanner from "../../Images/background/home-bg.jpg"

import newban from "../../Images/background/home-bg.jpg"


function Banner({title,subtitle}) {
    return (
      <div
        className="section bgi-cover-center bg-overlay-dark"
        data-background={newban}
        style={{ backgroundImage: `url(${newban})` }}
      >
        <div className="content-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-8 offset-md-2">
                <div className="cta-info">
                  <h2 className="" style={{ color: "#ff210b",marginTop:"65px" }}>
                    {title}
                  </h2>
                  <p className="text-white">{subtitle}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Banner
