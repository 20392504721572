import React, { Component } from 'react'
import store from "store"

import Layout from "../Components/reusable/Layout"
import Banner from "../Components/reusable/Banner"
import { Link } from 'gatsby'
export class Cart extends Component {

    state={
        products:[],
        formdata:{
            firstname:"",
            lastname:"",
            email:"",
            phone:"",
            address:"",
            message:"",
            country:"",
            company:"",
            city:"",
            postal:""

        }
    }

    componentDidMount(){
        if (store.get("products")) {
            this.setState({
                ...this.state,
                products:store.get("products")
            })
        }
    }

    handleDelete =(name)=>{
        const newdata = store.get("products").filter(item=>item.name!==name);
        console.log("new after delete=>",newdata);
        store.set("products",newdata)
         this.setState({
                ...this.state,
                products:newdata
            })
    }

handleChange =(e)=>{
this.setState({
        ...this.state,
        formdata:{
            ...this.state.formdata,
            [e.target.id]:e.target.value
        }
    })
}

handleSubmit = (e) =>{
    e.preventDefault()
    console.log("form data=>",this.state.formdata);
}

    render() {
        return (
            <Layout>
                <Banner title="Enquiry" />
               

<div className="checkout-area pt-5 pb-5">
<form onSubmit={this.handleSubmit} autoComplete="new-password">

    <div className="container">
        <div className="row">
          
            <div className="col-lg-7">
                <div className="form-group-wrap">
                    <h5> Details</h5>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-20">
                                <label>First Name</label>
                                <input className="form-control"  autoComplete="off" onChange={this.handleChange} required value={this.state.formdata.firstname} type="text" id="firstname" name="firstname" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-20">
                                <label>Last Name</label>
                                <input className="form-control"  onChange={this.handleChange} required value={this.state.formdata.lastname} type="text" id="lastname" name="lastname"  />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-20">
                                <label>Phone</label>
                                <input className="form-control"  onChange={this.handleChange} required value={this.state.formdata.phone} type="number" id="phone" name="phone" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-20">
                                <label>Email Address</label>
                                <input className="form-control"  onChange={this.handleChange} required value={this.state.formdata.email} type="email" id="email" name="email"  />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group mb-20">
                                <label>Company Name</label>
                                <input className="form-control"  onChange={this.handleChange} required value={this.state.formdata.company} type="text" id="company" name="company"  />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group mb-20">
                                <label>Street Address</label>
                                <input className="form-control"  onChange={this.handleChange}  value={this.state.formdata.address}  placeholder="House number and street name" type="text" id="address" name="address"  />
                                
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-20">
                                <label>Country</label>
                                <input className="form-control"  onChange={this.handleChange}  value={this.state.formdata.country} id="country" name="country"  type="text" placeholder="India"  />
                            </div>
                        </div>

                       

                        <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-20">
                                <label>Town / City</label>
                                <input className="form-control"  onChange={this.handleChange}  value={this.state.formdata.city} type="text" id="city" name="city"  />
                            </div>
                        </div>

                
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-20">
                                <label>Postcode / ZIP</label>
                                <input className="form-control"  onChange={this.handleChange}  value={this.state.formdata.postal_code} type="number" id="postal" name="postal" />
                            </div>
                        </div>
                        
                       
                    </div>
                   
                    <div className="col=md-12">
                        <div className="form-group">
                            <label>Your Message</label>
                            <textarea className="form-control" placeholder="Notes about your enquiry " id="message" name="message" rows="5"></textarea>
                        </div>
                    </div>
                    
                    
                     </div>
            </div>

            <div className="col-lg-5">
                <h5>Your order</h5>
                <div className="your-order-area">
                    
                    <div className="your-order-wrap gray-bg-4">
                        <div className="your-order-product-info">
                            <div className="your-order-top">
                                <ul>
                                   
                                    <li style={{fontWeight:"700"}}>Product</li>
                                    <li style={{fontWeight:"700"}}>Quantity <span className="ml-5"></span></li>
                                     {/* <li style={{fontWeight:"700"}}></li> */}
                                     
                                </ul>
                            </div>
                            <div className="your-order-middle">
                                <ul>
                                    {
                                        this.state.products.length?this.state.products.map((product,index)=>{
                                            return (
                                              <>
                                                <li key={index}>
                                                  <p
                                                    className="order-middle-left"
                                                    style={{ margin: "0px" }}
                                                  >
                                                    {product.name}
                                                  </p>
                                                  <p
                                                    className="order-price text-center mr-2"
                                                    style={{ margin: "0px" }}
                                                  >
                                                    {product.quantity}{" "}
                                                    <i
                                                      onClick={() =>
                                                        this.handleDelete(
                                                          product.name
                                                        )
                                                      }
                                                      className="fas fa-times-circle ml-5"
                                                    ></i>
                                                  </p>
                                                  {/* <span><i onClick={()=>this.handleDelete(product.name)} className="fas fa-times-circle"></i></span> */}
                                                </li>

                                                <li className="mb-4">
                                                  <span className="">
                                                    <small>
                                                      {product.message}
                                                    </small>
                                                  </span>
                                                </li>
                                              </>
                                            )
                                        }):null
                                    }
                                </ul>
                            </div>

                        </div>
                       
                    </div>
                    <div className="Place-order mt-5">
                        <button className="btn-hover btn-primary" type="submit" 
                       >Send Enquiry</button>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
    </form>
</div>



           
           
            </Layout>
        )
    }
}

export default Cart













function old(){
    {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                {/* <div className="container py-5">
                    <div className="row">
                        <div className="col-md-12">
<h4 className="mt-5">Products:-</h4>
<div style={{overflow:"visible"}}>
        <table className="table my-5 pb-5 table-responsive-md">
  <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Product Name</th>
      <th scope="col">Message</th>
      <th scope="col">Quantity</th>
      <th scope="col">action</th>
    </tr>
  </thead>
  <tbody>
    {
        this.state.products.length?this.state.products.map((product,index)=>{
            return(
    <tr key={index}>
        <th scope="row">{index+1}</th>
        <td>{product.name}</td>
        <td>{product.message}</td>
        <td>{product.quantity}</td>
        <td><i onClick={()=>this.handleDelete(product.name)} className="fas fa-times-circle"></i></td>
    </tr>)
        }):<p>You Don't have any product to enquiry</p>
    }
    
  </tbody>
</table>
</div>

</div>
</div>

<div className="row">
<div className="col-md-12">
<h4 className="mt-5">Please fill your Details:-</h4>

  <form onSubmit={this.handleSubmit}>
            <div className="container">
                <div className="row pb-5">
               
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                             <label>Name</label>
                            <input onChange={this.handleChange} value={this.state.formdata.name} required type="text" className="form-control" name="name" id="name" placeholder="Your Name" />
                        </div>
                    </div>
                       
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                             <label>Company Name</label>
                            <input onChange={this.handleChange} value={this.state.formdata.company} required type="text" className="form-control" id="company" name="company" placeholder="abc pvt. Ltd" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                             <label>Email</label>
                            <input onChange={this.handleChange} value={this.state.formdata.email} required type="email" className="form-control" name="email" id="email" placeholder="abc@example.com" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                             <label>Phone Number</label>
                            <input onChange={this.handleChange} value={this.state.formdata.phone} required type="mob no." className="form-control" id="phone" name="phone" placeholder="1234567890" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                             <label>Address</label>
                            <input onChange={this.handleChange} value={this.state.formdata.address} required type="text" className="form-control" id="address" name="address" placeholder="2nd floor, college road" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                             <label>Country</label>
                            <input onChange={this.handleChange} value={this.state.formdata.country} required type="text" className="form-control" id="country" name="country" placeholder="India" />
                        </div>
                    </div>
                    
                        <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                             <label>Your Message:-</label>
                            <textarea onChange={this.handleChange} value={this.state.formdata.message}  className="form-control" id="message" placeholder="anything you want to add.." name="message" rows="5"></textarea>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group send_message">
                            <input   type="submit" id="submit" className="btn btn-secondary btn-send" value="Enquire All" />   
                        </div>
                    </div>
               
                </div>
            </div>
            
        </form>

      
                       
                        </div>
                    </div>
                </div>
            */}
}